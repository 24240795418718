import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://ec28bd7057c7a91390592e5a87bb2a6f@o4507255570825216.ingest.de.sentry.io/4507255572856912',

	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,

	integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
